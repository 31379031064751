import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fiveg"
export default class extends Controller {
  connect() {
    $('.plans').show();
    $('.beginner').show();
    $('.signing_up').hide();
    $('.setting_up').hide();
    $('.device_details').hide();
    $('.btn-plan').on('click', function(){
      $('.faq-category li.btn').removeClass('active')
      $(this).parent().addClass('active')
      $('.plans').show();
      $('.btn-plan').addClass('#000');
      $('.signing_up').hide();
      $('.setting_up').hide();
      $('.device_details').hide();
      $('.phone').hide();
      $('.beginner').hide();
    });
    $('.btn-signing_up').on('click', function(){
      $('.faq-category li.btn').removeClass('active')
      $(this).parent().addClass('active')
      $('.signing_up').show();
      $('.plans').hide();
      $('.setting_up').hide();
      $('.device_details').hide();
      $('.phone').hide();
      $('.beginner').hide();
    });
    $('.btn-setting_up').on('click', function(){
      $('.faq-category li.btn').removeClass('active')
      $(this).parent().addClass('active')
      $('.setting_up').show();
      $('.signing_up').hide();
      $('.device_details').hide();
      $('.plans').hide();
      $('.phone').hide();
      $('.beginner').hide();
    });
    $('.btn-device_details').on('click', function(){
      $('.faq-category li.btn').removeClass('active')
      $(this).parent().addClass('active')
      $('.device_details').show();
      $('.setting_up').hide();
      $('.plans').hide();
      $('.signing_up').hide();
      $('.phone').hide();
      $('.beginner').hide();
    });
    $('.btn-for_beginners').on('click', function(){
      $('.faq-category li.btn').removeClass('active')
      $(this).parent().addClass('active')
      $('.beginner').show();
      $('.device_details').hide();
      $('.setting_up').hide();
      $('.plans').hide();
      $('.signing_up').hide();
      $('.phone').hide();
    });
    $('.btn-umaxx_liberty_phones').on('click', function(){
      $('.faq-category li.btn').removeClass('active')
      $(this).parent().addClass('active')
      $('.phone').show();
      $('.beginner').hide();
      $('.device_details').hide();
      $('.setting_up').hide();
      $('.plans').hide();
      $('.signing_up').hide();
    });

     tns({
      "items": 4,
      "controls": false,
      "responsive": {
        "200": {
          "items": 1,
          "controls": true,
          "fixedWidth": 250,
          "edgePadding": 30
        },
        "450": {
          "items": 1,
          "controls": true,
          "fixedWidth": 300,
          "edgePadding": 30
        },
        "767": {
          "items": 2
        },
        "1024": {
          "items": 2
        }
      },
      "container": "#rewind",
      "swipeAngle": false,
      "speed": 400,
      "loop": true,
      "fixedWidth": 350,
      "arrowKeys": true,
      "mouseDrag": true
    })
  }
}
