import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["currentPlanCategory"];

  connect() {
    console.log('Controller connected');
  }

  handlePlanChange(event) {
    const selectedRadio = event.target;
    const selectedCategory = selectedRadio.dataset.category;
    console.log('Selected Category:', selectedCategory);

    const currentPlanElement = this.currentPlanCategoryTarget;

    if (!currentPlanElement) {
      console.error('Current Plan Category element not found');
      return;
    }

    const currentCategory = currentPlanElement.dataset.category;
    console.log('Current Category:', currentCategory);

    if (currentCategory === '5g' && selectedCategory === 'combo') {
      this.showAdditionalFields();
    } else {
      this.hideAdditionalFields();
    }

    // Enable the "Change" button
    this.enableChangeButton();
  }

  showAdditionalFields() {
    const additionalFieldsElement = document.getElementById('additional-fields');
    if (additionalFieldsElement) {
      additionalFieldsElement.style.display = 'block';
    } else {
      console.error('Additional Fields element not found');
    }
  }

  hideAdditionalFields() {
    const additionalFieldsElement = document.getElementById('additional-fields');
    if (additionalFieldsElement) {
      additionalFieldsElement.style.display = 'none';
    } else {
      console.error('Additional Fields element not found');
    }
  }

  enableChangeButton() {
    const changeButton = document.getElementById('change-btn');
    console.log('butotn', changeButton)
    if (changeButton) {
      const selectedRadio = this.element.querySelector('input[name="selectedPlan"]:checked');
      changeButton.disabled = !selectedRadio;  // Enable button if a plan is selected
      console.log('ho gya')
    }
  }

  validateForm(event) {
    // Existing validation logic...
    const additionalFieldsElement = document.getElementById('additional-fields');
    const isAdditionalFieldsVisible = additionalFieldsElement.style.display !== 'none';
    console.log(isAdditionalFieldsVisible);

    const selectedRadio = this.element.querySelector('input[name="selectedPlan"]:checked');
    if (!selectedRadio) {
      console.error('No plan selected');
      event.preventDefault(); // Prevent form submission if no plan is selected
      return;
    }

    const selectedCategory = selectedRadio.dataset.category;
    const currentPlanElement = this.currentPlanCategoryTarget;

    if (!currentPlanElement) {
      console.error('Current Plan Category element not found');
      event.preventDefault(); // Prevent form submission if current plan category is not found
      return;
    }

    const currentCategory = currentPlanElement.dataset.category;
    const form = document.getElementById('change-plan-form');

    if (currentCategory === '5g' && selectedCategory === 'combo' && isAdditionalFieldsVisible) {
      console.log('Checking username and password...');
      const username = document.getElementById('username').value;
      const password = document.getElementById('password').value;

      // Define regex patterns
      const usernamePattern = /^[a-zA-Z0-9 _]+$/;
      const passwordPattern = /^[a-zA-Z0-9]{6,15}$/;

      const usernameValid = usernamePattern.test(username);
      const passwordValid = passwordPattern.test(password);

      const isUsernameEmpty = !username;
      const isPasswordEmpty = !password;

      // Determine if there are any validation errors
      const hasErrors = isUsernameEmpty || !usernameValid || isPasswordEmpty || !passwordValid;

      // Display error messages
      this.displayErrorMessages(isUsernameEmpty, !usernameValid, isPasswordEmpty, !passwordValid);

      if (hasErrors) {
        event.preventDefault(); // Prevent form submission if there are validation errors
        return;
      }

      form.submit();
    } else {
      form.submit();
    }
  }

  displayErrorMessages(isUsernameEmpty, isUsernameInvalid, isPasswordEmpty, isPasswordInvalid) {
    const usernameErrorElement = document.getElementById('username-error');
    const passwordErrorElement = document.getElementById('password-error');

    if (usernameErrorElement) {
      if (isUsernameEmpty) {
        usernameErrorElement.innerText = 'Username is required.';
        usernameErrorElement.style.display = 'block';
      } else if (isUsernameInvalid) {
        usernameErrorElement.innerText = 'Username must contain only alphabets, numbers, and underscores.';
        usernameErrorElement.style.display = 'block';
      } else {
        usernameErrorElement.style.display = 'none';
      }
    }

    if (passwordErrorElement) {
      if (isPasswordEmpty) {
        passwordErrorElement.innerText = 'Password is required.';
        passwordErrorElement.style.display = 'block';
      } else if (isPasswordInvalid) {
        passwordErrorElement.innerText = 'Password must be 6-15 characters long and contain only alphabets and numbers.';
        passwordErrorElement.style.display = 'block';
      } else {
        passwordErrorElement.style.display = 'none';
      }
    }
  }
}
