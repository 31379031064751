import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscriber--iptv"
export default class extends Controller {
  connect() {
    $('#edit_password_form').validate({
      rules: {
        "order[subscriber_name]": {
          required: true,
          usernameregex: true,
          minlength: 3,
          maxlength: 40
        },
        "order[password]": {
          required: true,
          minlength: 6,
          maxlength: 15,
          passwordregex: true
        },
        "confirm_password": {
          required: true,
          minlength: 6,
          maxlength: 15,
          passwordregex: true
        }
      },
      messages: {
        "order[subscriber_name]": {
          minlength: 'The subscriber name must be 3-40 characters long.',
          maxlength: 'The subscriber name must be 3-40 characters long.',
          required: 'The subscriber name is required.',
          usernameregex: 'Contains alphabets and number only.'
        },
        "order[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 6-15 characters long.',
          maxlength: 'The password must be 6-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        },
        "confirm_password": {
          required: 'The password is required.',
          minlength: 'The password must be 6-15 characters long.',
          maxlength: 'The password must be 6-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        }
      }
    })
    $.validator.addMethod('usernameregex', function(value){
      return /^[a-zA-Z0-9 _]+$/.test(value);
    },'Contains alphabets, number and _ only.');

    $.validator.addMethod('passwordregex', function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]{6,15}$/.test(value);
    }, 'Must follow the password pattern.');


    $('input').keyup(function(){
      $('.error-password, .error-confirm').html('')
    })
    $('.save_password').click(function(){
      var password = $('#order_password').val()
      var confirm_password = $('#confirm_password').val()
      if(confirm_password != password){
        $('.error-confirm').html('Does not match !!!')
        return false;
      }
    });
  }
}
