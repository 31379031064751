import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(".datepicker").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: "0d",
      endDate: "+1Y"
    })
  }
}
