import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('we are here..')
    if (window.location.pathname === '/') {
      var modals = document.querySelectorAll('.modal');

      if (modals.length > 0) {
        modals.forEach(function(modal) {
          modal.remove();
        });
      }
    }
  }
}
