import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--news"
export default class extends Controller {
  connect() {
    $('.new_latest_news').validate({
      rules: {
        "latest_news[creator_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        }
      },
      messages: {
        "latest_news[creator_name]": {
          nameregex: 'The creator name can only consist of alphabets.',
          required: 'The creator name is required.',
          minlength: 'The creator name must be 2-40 characters long.',
          maxlength: 'The creator name must be 2-40 characters long.'
        }
      }
    });

    $('.edit_latest_news').validate({
      rules: {
        "latest_news[creator_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        }
      },
      messages: {
        "latest_news[creator_name]": {
          nameregex: 'The creator name can only consist of alphabets.',
          required: 'The creator name is required.',
          minlength: 'The creator name must be 2-40 characters long.',
          maxlength: 'The creator name must be 2-40 characters long.'
        }
      }
    });

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');
  }
}
