import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--order"
export default class extends Controller {
  connect() {
    let password = $('#order_password').val()
      $('#order_password').attr('type', 'password')
      $('#order_password').val(password)
      $('.password-section .fa-eye-slash').hide()
      $('.password-section .fa-eye').show()

      $('.password-section span').click(function(){
        $('.password-section input').toggleClass('show_password');
        if($('.password-section .show_password').length == 1){
          $('#order_password').attr('type', 'text')
          $('.password-section .fa-eye-slash').show()
          $('.password-section .fa-eye').hide()
        }else{
          $('#order_password').attr('type', 'password')
          $('.password-section .fa-eye-slash').hide()
          $('.password-section .fa-eye').show()
        }
      })
    window.updateBoxStatus = function(element){
      let boxStatus = $(element).val();
      if(boxStatus != '' && confirm('Are you sure you wants to update the status of Box. Please confirm.')){
        $(element).next('button').click();
      }
    }
  }
}
