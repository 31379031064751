import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin"
export default class extends Controller {
  connect() {
    $('.menuburger_icon img').click(function () {
      $('.dashboard_sidebaar').toggleClass('active')
      $('.dashboard_right_content').toggleClass('reduce_pad_left')
    })

    // Video Slider
    // $(".audios_slider").slick({
    //   dots: false,
    //   infinite: true,
    //   centerMode: true,
    //   slidesToShow: 1,
    //   variableWidth: true
    // });
    // $(".videos_slider").slick({
    //   dots: false,
    //   infinite: true,
    //   centerMode: true,
    //   slidesToShow: 1,
    //   variableWidth: true
    // });
    // $(".games_slider").slick({
    //   dots: false,
    //   infinite: true,
    //   centerMode: true,
    //   slidesToShow: 4,
    //   variableWidth: true
    // });
    $('.header_search_wrap_btn').click(function(){
      $(this).toggleClass('active')
      $('.search_wrap').toggleClass('active_search')
    })
    $(document).click(function (event) {
      // if you click on anything except the modal itself or the "open modal" link, close the modal
      if (!$(event.target).closest(".search_wrap, .header_search_wrap_btn").length) {
        $('.header_search_wrap_btn').removeClass('active')
        $('.search_wrap').removeClass('active_search')
      }
    });

    $(document).ready(function(){
      $("#five_g_availability").on('change', function(){
        $(this).next().click();
      });
      $('#search_keyword').on('change', function(){
        $('#search_keyword').val($(this).val())
      });
    });
  }
}
