import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--order-list"
export default class extends Controller {
  connect() {
    var id = this.context.scope.element.dataset.id
    var page = this.context.scope.element.dataset.page
    var count = this.context.scope.element.dataset.count
    if(page == 'false'){
      count = parseInt(count) + 1
      var item = $(`#send_mail_order_${id}`).find('span')
      var sup = $(`#send_mail_order_${id}`).find('sup')
      item.text('Sent');
      setTimeout(function() {
        item.text('Send');
        sup.text(count);
      }, 5000);
    }

  }
}
