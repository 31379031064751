// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap
import * as Popper from "@popperjs/core"

import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

require('jquery-validation')

import { tns } from "tiny-slider"
window.tns = tns

// import './v2/custom';
// import * as geocomplete from 'geocomplete'
document.addEventListener("turbo:load", () => {
  if($('body').width() > 767){
    var selectorList = ['.extra-des-plan', '.card-height-section', '.description-plan-height', '.description-plan-height-tv', '.detail-section-card-tv']
    $.each(selectorList, function(index, selector){
      semtrixHeight(selector)
    })
  }
})
window.semtrixHeight = function(selector) {
  var maxHeight = findMaxHeight(selector)
  $(selector).css('min-height', (maxHeight + 10) + 'px');
}

window.findMaxHeight = function(selector) {
  let max = 0
  $(selector).each(function(index, item){
    var itemHeight = item.offsetHeight
    if(max < itemHeight)
      max = item.offsetHeight
  });
  return max
}
window.scrollImages = function(id, direction) {
  const imageList = document.getElementById(id);
  const scrollAmount = 80; // Adjust based on thumbnail size
  imageList.scrollTop += direction * scrollAmount;
}

window.scrollHrImages = function(id, direction) {
  const imageList = document.getElementById(id);
  const scrollAmount = 80;
  imageList.scrollLeft += direction * scrollAmount;
}

window.changeMainImage = function(mainImageId, src) {
  const mainImage = document.getElementById(mainImageId);
  $(mainImage).parent().find('img').removeClass('active')
  $(mainImage).addClass('active');
  mainImage.src = src;
}

// window.runCarousel = function(){
//   let currentImage = $('.image_previews img.active')
//   changeMainImage(currentImage.data('main-img'), currentImage.attr('src'))
//   // changeMainImage()
//   let nextImage = currentImage.next()
//   if(nextImage.length < 1){
//     nextImage = $('.image_previews img:first-child')
//   }
//   nextImage.addClass('active');
//   currentImage.removeClass('active');
// }

window.runCarousel = function(ids) {
  // Ensure the parameter is an array and has at least one element
  if (!Array.isArray(ids) || ids.length === 0) {
    console.error('Invalid or empty array of IDs provided.');
    return;
  }

  // Iterate through each ID in the array
  ids.forEach(id => {
    const container = $(`#${id}`);
    if (container.length === 0) {
      console.warn(`No element found with ID: ${id}`);
      return;
    }

    let currentImage = container.find('img.active');
    if (currentImage.length === 0) {
      console.warn(`No active image found in container with ID: ${id}`);
      return;
    }

    changeMainImage(currentImage.data('main-img'), currentImage.attr('src'));

    let nextImage = currentImage.next();
    if (nextImage.length < 1) {
      nextImage = container.find('img:first-child');
    }

    nextImage.addClass('active');
    currentImage.removeClass('active');
  });
};
