import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscriber--user"
export default class extends Controller {
  connect() {
    $('#order_edit_form').validate({
      rules: {
        "order[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "order[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "order[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "order[phone]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10,
          required: true
        }
      },
      messages: {
        "order[email]": {
          required: 'The email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Contains @ and .'
        },
        "order[first_name]": {
          nameregex: 'The first name can only consist of alphabets.',
          required: 'The first name is required.',
          minlength: 'The first name must be 2-40 characters long.',
          maxlength: 'The first name must be 2-40 characters long.'
        },
        "order[last_name]": {
          nameRegex: 'The last name can only consist of alphabets.',
          required: 'The last name is required.',
          minlength: 'The last name must be 2-40 characters long.',
          maxlength: 'The last name must be 2-40 characters long.'
        },
        "order[phone]": {
          phoneregex: 'The phone contain only 10 digits.',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
          required: 'The phone number is required.'
        }
      }
    });
    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');
  }
}
