import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='about'
export default class extends Controller {
  connect() {
    $(".accordian-tabs .set > a").on("click", function() {
      if($(this).hasClass("active-tab")) {
        $(this).removeClass("active-tab");
        $(this).siblings(".content").slideUp(200);
      }
      else {
        $(".accordian-tabs .set > a").removeClass("active-tab");
        $(this).addClass("active-tab");
        $(".content").slideUp(200);
        $(this).siblings(".content").slideDown(200);
      }
    });
  }
}
