import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="header"
export default class extends Controller {
  connect() {
    $(document).on('click', '.open-menu .fa-bars', function(){
      $(".open-menu .fa-bars").addClass("scale-zero");
      $(".open-menu .fa-close").removeClass("scale-zero");
      $(".open-menu-dropdown").slideDown('fast');
    });
    $(document).on('click', '.open-menu .fa-close', function(){
      $(".open-menu .fa-close").addClass("scale-zero");
      $(".open-menu .fa-bars").removeClass("scale-zero");
      $(".open-menu-dropdown").slideUp("fast");
    });
  }
}
